// third-party
import {createSlice} from "@reduxjs/toolkit";
import {dispatch} from "../index";
import {DefaultRootStateProps} from "types/pmp";
import { ReactNode } from "react";
import { JSX } from "react/jsx-runtime";
import { isNullableType } from "graphql";
import useApiUrl from 'hooks/useApiUrl';

const initialState: DefaultRootStateProps["broker"] = {
    error: null,
    description: null,
    loginSnapTrade: null,
    tradeAccountLists: null,
    accountHoldings:null,
    snapTradeJsonSuccess:null,
    disabledBrokerages:null,
    LoginDisabledBrokerages:null,
  
};

const slice = createSlice({
    name: "broker",
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload; // Extract error message
        },

        getDescriptionSuccess(state, action) {
            state.description = action.payload;
        },
        getLoginURISuccess(state,action){
            state.loginSnapTrade=action.payload;
        },
        getListAccountsSuccess(state,action){
            state.tradeAccountLists =action.payload;
        },
        getAccountHoldingsSuccess(state,action){
            state.accountHoldings =action.payload;
        },
        getSnapTradeJsonSuccess(state,action){
            state.snapTradeJsonSuccess=action.payload;
        },
        getBrokeragesSuccess(state,action){
            state.disabledBrokerages=action.payload;
        },
        getLoginDisabledBrokeragesSuccess(state,action){
            state.LoginDisabledBrokerages=action.payload;
        }
    }
});

export default slice.reducer;

// User Registration

export  function registerUser(user_id: string, token: string) {
    return async () => {
        try {
            let response = await fetch(`https://brokerbridge.versaquant.com:5002/register_user/`, {
                method: 'POST',
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    user_id: user_id
                })
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            let result = await response.json();

            dispatch(slice.actions.getDescriptionSuccess(result));

        } catch (error) {
            console.error("error is ", error);
            dispatch(slice.actions.hasError(error));
        }
    }
}
// User Login URI
export function loginURI(token: any,source:any) {
    return async () => {
        try {
            let response = await fetch(`https://brokerbridge.versaquant.com:5002/login_uri/?source=${source}`, {
                method: 'POST',
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            let result = await response.json();

            dispatch(slice.actions.getLoginURISuccess(result));

        } catch (error) {
            console.error("error is ", error);
            dispatch(slice.actions.hasError(error));
        }
    }
}


// Trading accounts list
export  function getListAccounts(user_id: string, token: string) {
    return async () => {
        try {
            let response = await fetch(`https://brokerbridge.versaquant.com:5002/list_accounts/`, {
                method: 'POST',
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: user_id}) 
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            let result = await response.json();

            dispatch(slice.actions.getListAccountsSuccess(result));

        } catch (error) {
            console.error("error is ", error);
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function getAccountHoldings(account_id: any, token: any) {
    return async (dispatch: any) => {
        try {
            let response = await fetch(`https://brokerbridge.versaquant.com:5002/get_account_holdings/?account_id=${account_id}`, {
                method: 'POST',
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
 
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
 
            let result = await response.json();
 
            dispatch(slice.actions.getAccountHoldingsSuccess(result));
        } catch (error) {
            console.error("error is ", error);
            dispatch(slice.actions.hasError(error));
        }
    }
}
export function createBCTFromSnapTardeJSON(token: any, account_id: any) {
    return async () => {
        let url = useApiUrl() + `run3/pc.createBCTFromSnapTradeJSON/["${account_id}"]`;
        try {
            await fetch(url, {
                mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Bearer ${token}`,
                }
            }).then(async response => {
                {
                    response.json().then(async result => {
                        dispatch(slice.actions.getSnapTradeJsonSuccess(result));
                    });
                }
            }).catch(e => {
                console.log(e);
            });
        } catch (error) {
            console.log("error is " + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function checkDisabledBrokerages(token: any) {
    return async () => {
      try {
        const response = await fetch(`https://brokerbridge.versaquant.com:5002/check_disabled_brokerages/`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
          },
        //   body: JSON.stringify({ token:token}) 
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const result = await response.json();
        dispatch(slice.actions.getBrokeragesSuccess(result));
      } catch (error) {
        console.log('Error:', error);
        
      }
    };
  }
  
 
  export function   loginSnapTradeURI(token: any, source: any, reconnectID?: any) {
    return async () => {
      try {
        const body = { source, reconnectID };
        const response = await fetch(`https://brokerbridge.versaquant.com:5002/login_uri/?source=${source}&reconnectID=${reconnectID}`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const result = await response.json();
        dispatch(slice.actions.getLoginDisabledBrokeragesSuccess(result));
      } catch (error) {
        console.log('Error:', error);
        
      }
    };
  }