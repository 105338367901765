const getApiUrl = () => {
    const isDev = (window.location.origin.includes('localhost') || window.location.origin.includes('sandbox.versaquant') ); // Check if running locally

    // Log based on the environment
    if (isDev) {
        console.log('Running in development mode');
        return 'https://api.dev.versaquant.com:5000/'; // Development API URL
    } else {
        console.log('Running in production mode');
        return 'https://api.versaquant.com:5000/'; // Production API URL
    }
};

export default getApiUrl;
