import { useEffect, useState } from 'react';

// material-ui
import {  List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, ProfileOutlined, LogoutOutlined, UserOutlined, WalletOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
}
function getPathIndex(pathname: string) {
  let selectedTab = 0;
  switch (pathname) {
    case '/apps/profiles/user/stripe':
      selectedTab = 2;
      break;
      case '/apps/profiles/user/personal':
        selectedTab = 1;
        break;
        default:
          selectedTab = 0;
  }
  return selectedTab;
}
const ProfileTab = ({ handleLogout }: Props) => {
  // const [selectedIndex, setSelectedIndex] = useState(0);
  // const handleListItemClick = (event: React.MouseEvent<HTMLDivElement>, index: number) => {
  //   setSelectedIndex(index);
  // };
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(getPathIndex(pathname));
  const handleListItemClick = (index: number, route: string) => {
    setSelectedIndex(index);
    navigate(route);
  };

  useEffect(() => {
    setSelectedIndex(getPathIndex(pathname));
  }, [pathname]);
  const handleLogoutClick = () => {
    // Clear the token from localStorage/sessionStorage
    localStorage.removeItem('authToken');  // or use sessionStorage.removeItem('authToken') if using session storage
    sessionStorage.removeItem('authToken'); // In case you're using both

    // Call the handleLogout prop for any additional logout logic
    handleLogout();

    // Redirect to the login page
    
  };


  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
     <ListItemButton selected={selectedIndex === 0} onClick={() => handleListItemClick(1, '/apps/profiles/user/personal')}>
        <ListItemIcon>
          <UserOutlined />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 1} onClick={() => handleListItemClick(2, '/apps/profiles/user/stripe')}>
        <ListItemIcon>
        <WalletOutlined />
        </ListItemIcon>
        <ListItemText primary="Billing" />
      </ListItemButton>

      {/* <ListItemButton selected={selectedIndex === 3} onClick={(event: React.MouseEvent<HTMLDivElement>) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <ProfileOutlined />
        </ListItemIcon>
        <ListItemText primary="Social Profile" />
      </ListItemButton> */}

      <ListItemButton selected={selectedIndex === 2} onClick={handleLogoutClick}>
        <ListItemIcon>
          <LogoutOutlined />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

export default ProfileTab;
