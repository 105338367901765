import useAuth from '../sections/apps/profiles/user/useAuth';
import getApiUrl from './useApiUrl';

let url=getApiUrl();
const getJobId = async (token, forceUpdate = false) => {
    const key = 'jobId';
    

    try {
        // Fetch jobId from the API
        const response = await fetch(url+'run3/pd.getJobInfo/[]', {
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Assume the response is an array, and the jobId is in the first object
        if (Array.isArray(data) && data.length > 0 && data[0].jobID) {
            const jobId = data[0].jobID;

            // Save jobId to local storage
            localStorage.setItem(key, JSON.stringify(jobId));

            return jobId;
        }
    } catch (error) {
        console.error('Fetching job ID failed:', error);
        return null;
    }

    // If fetching from the API fails or no valid jobId is obtained, try to get jobId from local storage
    let storedJobId = localStorage.getItem(key);

    if (storedJobId && storedJobId !== "undefined" && !forceUpdate) {
        return JSON.parse(storedJobId);
    }

    // Return null if no valid jobId is found in the API response
    return null;
};

export default getJobId;
