// third-party
import {createSlice} from '@reduxjs/toolkit';

// project imports
import {dispatch,useSelector} from '../index';

// types
import {DefaultRootStateProps} from 'types/pmp';
import getJobId from "../../hooks/getJobId";
import useApiUrl from 'hooks/useApiUrl';
import getApiUrl from 'hooks/useApiUrl';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['style'] = {
    error: null,
    styles: [],
    clientStyles: null,
    checkBoxData: null,
    time: 5,
    risk: 5,
    types: [],
    style: null,
    relatedPortfolios: [],
    reviews: [],
    addresses: [],
    megaTrends: [],
    values: [],
    saa: [],
};

const slice = createSlice({
    name: 'style',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        getStylesSuccess(state, action) {
            state.types = action.payload

        },
        saveCombinedData(state, action) {
            let payload = action.payload;
            let uniqueStyleTypes = new Set(payload.styles.map((s: any) => s.styleType));

            let userStyles = Array.from(uniqueStyleTypes, styleType => {
                let styles = payload.styles.filter((item: any) => item.styleType === styleType)
                    .map((item: any) => ({
                        styleName: item.styleName,
                        selected: payload.clientStyles.selectedAlignTable.some(o => o.styleName === item.styleName)
                    }));
                return {
                    styleType: styleType,
                    styles: styles
                };
            });
            state.checkBoxData = userStyles;
        },
        getClientAlignSuccess(state, action) {
            state.clientStyles = action.payload;

        },
       


        getSaaSuccess(state, action) {
            state.saa = action.payload;
        },

        filterPortfoliosSuccess(state, action) {
            state.styles = action.payload;
        },
        getPortfolioSuccess(state, action) {
            state.style = action.payload;
        },

        getRelatedPortfoliosSuccess(state, action) {
            state.relatedPortfolios = action.payload;
        },

        getPortfolioReviewsSuccess(state, action) {
            state.reviews = action.payload;
        },

        getAddressesSuccess(state, action) {
            state.addresses = action.payload;
        },

        addAddressSuccess(state, action) {
            state.addresses = action.payload;
        },

        editAddressSuccess(state, action) {
            state.addresses = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;



export function getClientStyles(token: any, jobID: any) {
    return async (dispatch: any) => {
        try {
            const response = await jobID && await fetch(`${getApiUrl()}run3/pa.getClientAlign/["${jobID}"]`, {
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = await response.json();
            data.riskLevel *= 10;
            dispatch(slice.actions.getClientAlignSuccess(data));
            return data;
        } catch (e) {
            console.log('error is ' + e);
            dispatch(slice.actions.hasError(e));
            throw e;
        }
    }
}




export function getStyles(token: any) {
    return async (dispatch: any) => {
        try {
            const response = await fetch(`${getApiUrl()}run3/pa.getStyles/%5B%5D`, {
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                }
            });
            const data = await response.json();
            dispatch(slice.actions.getStylesSuccess(data));
            return data;
        } catch (error) {
            console.log('error is ' + error);
            dispatch(slice.actions.hasError(error));
            throw error;
        }
    }
}

export function getCheckboxData(token: any,jobID:any) {
    return async (dispatch: any, getState: any) => {
        try {
           
            const clientStylesData = await dispatch(getClientStyles(token,jobID));
            const stylesData = await dispatch(getStyles(token));

            // You can dispatch another action here to save combined data into the state
            dispatch(slice.actions.saveCombinedData({
                clientStyles: clientStylesData,
                styles: stylesData,
            }));
        } catch (error) {
            console.log('error is ' + error);
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function getSAA(token: any, time: any, risk: any) {
    return async () => {
        try {
            await fetch(`${getApiUrl()}run3/pa.getSAA/%5B%5B%5D%2C${time}%2C${risk / 10}%5D`, {
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${token}`,
                }
            }).then(response => {
                response.json().then(result => {
                    let filter = result.SAA;
                    delete filter?.AllocByRiskAndTime;
                    let values: any = [];
                    if (filter) {
                        Object.entries(filter)?.map(a => {
                            if (a[0]) {
                                let temp1 = "";
                                if (a[0] === "AggregatedFixedIncome") {
                                    temp1 = "Fixed Income";
                                }
                                if (a[0] === "AggregatedEquitiesAgg") {
                                    temp1 = "Equities";
                                }
                                values.push({"name": temp1, "y": a[1]});
                            }
                        });
                    }
                    dispatch(slice.actions.getSaaSuccess(values));
                }).catch(e => {
                    console.log(e);
                });
            });
        } catch (error) {
            console.log('error is ' + error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
